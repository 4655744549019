// -----------------------------------------------------------------------------
// This file contains all styles related to the nav of the site/application.
// -----------------------------------------------------------------------------

@layer base {
	.nav {
		@apply invisible fixed bottom-12 left-full top-[5.125rem] z-0 m-0 flex w-[calc(100%_-_2rem)] list-none flex-col gap-y-6 bg-white px-8 py-16 motion-safe:transition-all sm:p-6 md:p-0;
		@screen md {
			@apply pointer-events-auto visible static block h-auto bg-transparent py-0;
		}
		.menu-is-open & {
			@apply pointer-events-auto visible -translate-x-[calc(100%_+_1rem)] overflow-y-auto shadow-2xl;
			@screen md {
				@apply pointer-events-auto static inset-x-auto flex translate-x-0 overflow-y-visible shadow-none;
			}
		}
	}
	.menu {
		@apply flex flex-col gap-12 md:flex-row md:justify-center md:gap-4 lg:gap-8 xl:gap-24;
		&__li {
			@apply relative border-b border-primary md:border-none;
			&:after {
				@apply absolute inset-x-0 -bottom-[1px] origin-left scale-x-0 border-b-4 border-b-quaternary opacity-0 content-[""] motion-safe:transition-all md:bottom-0;
			}
			&--active,
			&:hover {
				@apply after:scale-x-100 after:opacity-100;
			}
			&--active {
				@apply font-medium;
			}
		}
		&__a {
			@apply block py-3;
		}
	}
}

/* Hamburger Button */
.hamburger-button.active .line-1,
.hamburger-button[aria-expanded="true"] .line-1 {
	--tw-translate-y: 0px;
	--tw-rotate: 45deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
		skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
		scaleY(var(--tw-scale-y));
}

.hamburger-button.active .line-2,
.hamburger-button[aria-expanded="true"] .line-2 {
	--tw-translate-x: 0.75rem;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
		skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
		scaleY(var(--tw-scale-y));
	opacity: 0;
}

.hamburger-button.active .line-3,
.hamburger-button[aria-expanded="true"] .line-3 {
	--tw-translate-y: 0px;
	--tw-rotate: -45deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
		skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
		scaleY(var(--tw-scale-y));
}
