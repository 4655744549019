// -----------------------------------------------------------------------------
// This file contains all application-wide variables.
// -----------------------------------------------------------------------------

:root {
	--primary-color: #3d4539;
	--secondary-color: #006851;
	--tertiary-color: #b4be0f;
	--quaternary-color: #ae1065;
	--font-size: 1rem;
	--font-family: "Inter", sans-serif;
	--background-color: #dae0e0;
	--font-color: #121212;
	--page-width: 650px;
}
