// Social media icons hover effect

.social {
	path,
	rect {
		@apply motion-safe:transition-all;
	}
	&:hover {
		path {
			@apply fill-white;
		}
		rect {
			@apply fill-primary;
		}
	}
}
