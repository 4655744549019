/* prettier-ignore */
@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

// Configuration and helpers
@import "abstracts/variables", "abstracts/functions", "abstracts/mixins";

// Base stuff
@import "base/base", "base/fonts", "base/typography", "base/helpers";

// Layout-related sections
@import "layout/nav", "layout/footer";

// Components
@import "components/accordion";
@import "components/video";

// Elements
@import "elements/headings", "elements/button";

// Page-specific styles
@import "pages/home";

// Themes
@import "themes/default";

// Font
@import "base/Gotham";

// Social media icons hover effect
@import "layout/social";

// Vendors
