.c-accordion {
	&__head {
		@apply flex items-center gap-4 px-4 py-3 text-left;
	}

	&__icon {
		@apply flex-shrink-0;
		&--closed {
			.c-accordion__head--active & {
				@apply hidden;
			}
		}
		&--open {
			@apply hidden;
			.c-accordion__head--active & {
				@apply block;
			}
		}
	}

	&__content {
		@apply hidden px-6 py-5;

		&--active {
			@apply block;
		}
	}
}
